import { Action, createReducer, on } from '@ngrx/store';
import { cleanupPendingState } from '../helpers/store';
import {
  downloadFileByUrl,
  downloadFileByUrlFail,
  downloadFileByUrlSuccess
} from './files.actions';
import { FilesState, filesInitialState } from './files.state';

export const filesReducer = createReducer<FilesState, Action>(
  filesInitialState,
  on(
    downloadFileByUrl,
    (state: FilesState, action): FilesState => ({
      ...state,
      pending: [...state.pending, action]
    })
  ),
  on(
    downloadFileByUrlSuccess,
    (state: FilesState, action): FilesState => cleanupPendingState(state, action)
  ),
  on(
    downloadFileByUrlFail,
    (state: FilesState, action): FilesState => cleanupPendingState(state, action, action.payload)
  )
);
