export enum ServiceName {
  AZURE = 'azure',
  SLACK = 'slack',
  GITHUB = 'github',
  GITLAB = 'gitlab',
  JIRA = 'jira',
  MONDAY = 'monday',
  SERVICENOW = 'servicenow',
  SNYK = 'snyk',
  STAR = 'star',
  TRELLO = 'trello'
}

export function isExternalScanConfigService(features: ('issue_sources' | 'boards')[]): boolean {
  return features.some((feature) => feature === 'issue_sources');
}

export function isMultipleIssueSourceSelectSupported(service: ServiceName): boolean {
  return service === ServiceName.SNYK;
}
