import { HttpErrorResponse } from '@angular/common/http';
import { InterceptorOptions } from '../services';

export class TokenInterceptorOptions implements InterceptorOptions {
  public readonly prefixes = ['/api/v1', '/api/v2', '/api/v3'];

  private readonly logoutEndpoint = new RegExp(`^${this.prefixes[0]}/auth/logout/?$`, 'i');

  public readonly tokenEndpoint = new RegExp(`^${this.prefixes[0]}/auth/token`, 'i');

  public readonly excludePaths: readonly RegExp[] = [
    new RegExp(`^${this.prefixes[0]}/auth/mfa/?$`, 'i'),
    new RegExp(`^${this.prefixes[0]}/auth/login`, 'i'),
    new RegExp(`^${this.prefixes[0]}/password-reset/?$`, 'i'),
    new RegExp(`^${this.prefixes[0]}/verify-password-reset/?$`, 'i'),
    this.tokenEndpoint,
    this.logoutEndpoint
  ];

  public shouldTrackError(err: HttpErrorResponse): unknown {
    let pathname: string;

    try {
      ({ pathname } = new URL(err.url));
    } catch {
      pathname = err.url;
    }

    return (
      !this.logoutEndpoint.test(pathname) && (err.status !== 403 || typeof err.error === 'string')
    );
  }
}
