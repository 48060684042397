import { Inject, Injectable, Optional } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { UserDateService } from './user-date.service';

@Injectable()
export class DateAdapterMatCalendar extends NativeDateAdapter {
  constructor(
    private readonly userDateService: UserDateService,
    @Optional() @Inject(MAT_DATE_LOCALE) matDateLocale: string
  ) {
    super(matDateLocale);
  }

  public override getFirstDayOfWeek(): number {
    return this.userDateService.dateSettings.startOfWeek;
  }

  public override today(): Date {
    return this.userDateService.applyTz(new Date());
  }
}
