import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { SentryErrorHandler, createErrorHandler } from '@sentry/angular';
import { HttpInterceptorErrorHandlerService } from '../services';
import { ErrorHandlerEffects } from '../store/error-handler.effects';

@NgModule({
  imports: [EffectsModule.forFeature([ErrorHandlerEffects])],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorErrorHandlerService,
      multi: true
    },
    {
      provide: SentryErrorHandler,
      useValue: createErrorHandler({ logErrors: false })
    }
  ]
})
export class ErrorHandlerModule {}
