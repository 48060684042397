import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject, filter, fromEvent, merge, switchMap, take, takeUntil } from 'rxjs';
import { SatPopoverAnchorDirective } from '../sat-popover';
import { CONFIRM_DIALOG_PANEL_CLASS } from '../services';

interface Popover {
  readonly opened$: Observable<void>;
  readonly closed$: Observable<void>;
  readonly close: () => void;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[matMenuTriggerFor], [satPopoverAnchor]'
})
export class CloseOnBackdropClickDirective implements OnInit, OnDestroy {
  private readonly gc = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() private readonly matMenuTriggerFor: MatMenuTrigger,
    @Optional() private readonly satPopoverAnchor: SatPopoverAnchorDirective,
    private readonly elementRef: ElementRef<HTMLElement>
  ) {}

  public ngOnInit(): void {
    if (this.matMenuTriggerFor) {
      this.setup({
        opened$: this.matMenuTriggerFor.menuOpened,
        closed$: this.matMenuTriggerFor.menuClosed,
        close: this.matMenuTriggerFor.closeMenu.bind(this.matMenuTriggerFor)
      });
    } else if (this.satPopoverAnchor && !this.satPopoverAnchor.popover.hasBackdrop) {
      this.setup({
        opened$: this.satPopoverAnchor.popover.opened,
        closed$: this.satPopoverAnchor.popover.closed,
        close: this.satPopoverAnchor.popover.close.bind(this.satPopoverAnchor.popover)
      });
    }
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private setup(popover: Popover): void {
    popover.opened$
      .pipe(
        switchMap(() =>
          merge(
            fromEvent(this.document.defaultView, 'click', {
              capture: true
            }),
            fromEvent(this.document.defaultView, 'scroll', {
              passive: true,
              capture: true
            })
          ).pipe(
            filter(
              (e: MouseEvent | Event) =>
                // excluding clicks in confirm dialog (e.g. Account menu -> Sign out)
                !(e.target as HTMLElement).closest(`.${CONFIRM_DIALOG_PANEL_CLASS}`) &&
                // excluding clicks in overlay panes (e.g. dropdown panel inside sat-popover)...
                ((!(e.target as HTMLElement).closest('.cdk-overlay-pane') &&
                  // ...and dropdown panels backdrops...
                  !(e.target as HTMLElement).closest('.cdk-overlay-backdrop')) ||
                  // ...except clicks inside modal dialogs (e.g. info tooltips)
                  !!(e.target as HTMLElement).closest('.cdk-dialog-container'))
            ),
            take(1),
            takeUntil(popover.closed$)
          )
        ),
        takeUntil(this.gc)
      )
      .subscribe((e) => {
        popover.close();

        // stop propagation of click on own trigger to avoid close and immediate open
        if (
          e instanceof PointerEvent &&
          this.elementRef.nativeElement.contains(e.target as HTMLElement)
        ) {
          e.stopPropagation();
        }
      });
  }
}
