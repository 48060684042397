<share-entry *ngIf="{ scopePermissions: scopePermissions$ | async } as sync" class="entry">
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.ADMIN_ORGS]"
    [icon]="'domain'"
    [label]="'Organizations'"
    [link]="'/organizations'"
    [tooltip]="'View organizations'">
    Organizations
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.ADMIN_ISSUE_TYPES]"
    [icon]="'report'"
    [label]="'Issue types'"
    [link]="'/issue-types'"
    [tooltip]="'View issue types'">
    Issue types
  </share-nav-item>
  <share-nav-item
    *ngIf="sync.scopePermissions[Scope.ADMIN_TAGS]"
    [icon]="'topic'"
    [label]="'Organization tags'"
    [link]="'/tags'"
    [tooltip]="'View organization tags'">
    Organization tags
  </share-nav-item>
</share-entry>
