import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Token } from '@neuralegion/api';
import { UserSessionStorageService } from '@neuralegion/browser-storage';
import { ForgotPasswordService } from '../services';
import {
  forgotPassword,
  forgotPasswordFail,
  forgotPasswordSuccess,
  resetPassword,
  resetPasswordFail,
  resetPasswordSuccess,
  verifyResetPassword,
  verifyResetPasswordSuccess
} from './forgot-password.actions';

@Injectable()
export class ForgotPasswordEffects {
  public readonly forgotPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword),
      exhaustMap((action: ReturnType<typeof forgotPassword>) =>
        this.forgotPasswordService.forgotPassword(action.payload).pipe(
          map(() => forgotPasswordSuccess(action.payload)),
          catchError((err: HttpErrorResponse) => of(forgotPasswordFail(err.error)))
        )
      )
    )
  );

  public readonly resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPassword),
      exhaustMap((action: ReturnType<typeof resetPassword>) =>
        this.forgotPasswordService.resetPassword(action.payload).pipe(
          tap((token: Token | undefined) => {
            if (token) {
              this.userSessionStorageService.set(token);
            }
          }),
          map((token: Token | undefined) => resetPasswordSuccess({ redirectToLogin: !token })),
          catchError((err: HttpErrorResponse) => of(resetPasswordFail(err.error)))
        )
      )
    )
  );

  public readonly verifyResetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyResetPassword),
      exhaustMap((action: ReturnType<typeof verifyResetPassword>) =>
        this.forgotPasswordService.verifyResetPassword(action.payload).pipe(
          map(({ token }: { token: string }) =>
            verifyResetPasswordSuccess({ recoverToken: token })
          ),
          catchError((err: HttpErrorResponse) => of(resetPasswordFail(err.error)))
        )
      )
    )
  );

  public readonly verifyResetPasswordSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(verifyResetPasswordSuccess),
        tap((action: ReturnType<typeof verifyResetPasswordSuccess>) => {
          void this.router.navigate([`/forgot-password/${action.payload.recoverToken}`]);
        })
      ),
    { dispatch: false }
  );

  public readonly forgotPasswordSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(forgotPasswordSuccess),
        tap((action: ReturnType<typeof forgotPasswordSuccess>) => {
          void this.router.navigate(['/forgot-password/info', action.payload.email]);
        })
      ),
    { dispatch: false }
  );

  public readonly resetPasswordSuccess$: Observable<Action> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetPasswordSuccess),
        tap((action: ReturnType<typeof resetPasswordSuccess>) => {
          if (action.payload.redirectToLogin) {
            void this.router.navigate(['/login']);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly forgotPasswordService: ForgotPasswordService,
    private readonly userSessionStorageService: UserSessionStorageService
  ) {}
}
