import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  PipeTransform
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import {
  Observable,
  Subject,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { FilterConfig, TablePaginationConfig } from '@neuralegion/api';
import { FilterSerializationService } from '@neuralegion/core';
import { AllFiltersValue, GroupedFiltersValue, SearchFilterValue } from '../../models';
import { PaginationStore, TableFiltersService } from '../../services';

interface FilterFormValue {
  groupedFilters: GroupedFiltersValue;
  searchFilter: SearchFilterValue;
}

@Component({
  selector: 'share-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFiltersComponent<T extends { id: string; createdAt?: string }>
  implements OnDestroy, OnInit
{
  @Input()
  public title: string;

  @Input()
  public filterNamePipe: PipeTransform;

  @Input()
  public appliedFiltersLabelsPipe?: PipeTransform;

  @Input()
  public pending$: Observable<boolean>;

  public pagination$: Observable<TablePaginationConfig>;

  public readonly filterForm = new FormGroup({
    groupedFilters: new FormControl<GroupedFiltersValue>(null),
    searchFilter: new FormControl<SearchFilterValue>(this.tableFiltersService.searchFilterDefault)
  });

  public readonly groupedFiltersControl = this.filterForm.controls.groupedFilters;
  public readonly searchFilterControl = this.filterForm.controls.searchFilter;

  public readonly groupedFiltersConfig: readonly FilterConfig[] =
    this.tableFiltersService.groupedFiltersConfig;
  public readonly searchFilterConfig: readonly FilterConfig[] =
    this.tableFiltersService.searchFilterConfig;

  private readonly queryParamsChanged$: Observable<boolean> = this.route.queryParams.pipe(
    pairwise(),
    map(([prev, cur]: [Params, Params]) => !equal(prev, cur)),
    startWith(false),
    distinctUntilChanged()
  );

  private filters$: Observable<AllFiltersValue>;

  private readonly gc = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly filterSerializationService: FilterSerializationService,
    private readonly paginationStore: PaginationStore<T>,
    private readonly tableFiltersService: TableFiltersService
  ) {}

  public ngOnInit(): void {
    this.pagination$ = this.paginationStore.pagination$;
    this.filters$ = (this.paginationStore.filter$ as Observable<AllFiltersValue>).pipe(
      switchMap((storeFilterValue: AllFiltersValue) =>
        this.tableFiltersService.getValidFilters(storeFilterValue)
      ),
      takeUntil(this.gc),
      shareReplay(1)
    );

    this.initFilterControlsListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  private initFilterControlsListener(): void {
    this.filterForm.valueChanges
      .pipe(
        filter(() => this.filterForm.valid),
        distinctUntilChanged(equal),
        map(({ groupedFilters, searchFilter }: FilterFormValue) =>
          this.filterSerializationService.serializeToObject(
            this.tableFiltersService.getAllFilters(groupedFilters, searchFilter)
          )
        ),
        withLatestFrom(this.filters$, this.queryParamsChanged$),
        filter(
          ([value, storedValue, queryParamsChanged]) =>
            queryParamsChanged || !equal(value, storedValue)
        ),
        map(([value]) => value),
        takeUntil(this.gc)
      )
      .subscribe((value) => {
        this.paginationStore.updateFilter(value);
      });

    this.filters$
      .pipe(
        distinctUntilChanged(equal),
        map(
          (
            value: AllFiltersValue
          ): { groupedFilters: GroupedFiltersValue; searchFilter: SearchFilterValue } => ({
            groupedFilters: this.filterSerializationService.serializeToObject(
              this.tableFiltersService.getGroupedFilters(value)
            ),
            searchFilter: this.filterSerializationService.serializeToObject(
              this.tableFiltersService.getSearchFilter(value)
            )
          })
        ),
        takeUntil(this.gc)
      )
      .subscribe((value) => {
        this.filterForm.setValue(value);
      });
  }
}
