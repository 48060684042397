import { createAction } from '@ngrx/store';

export const downloadFileByUrl = createAction(
  '[Files] DOWNLOAD_BY_URL',
  (payload: { url: string }) => ({ payload })
);

export const downloadFileByUrlSuccess = createAction('[Files] DOWNLOAD_BY_URL_SUCCESS', () => ({
  parentType: downloadFileByUrl.type
}));

export const downloadFileByUrlFail = createAction(
  '[Files] DOWNLOAD_BY_URL_FAIL',
  (payload: string) => ({
    payload,
    parentType: downloadFileByUrl.type
  })
);
