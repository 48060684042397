import { ErrorHandler, Injectable } from '@angular/core';
import { ExternalErrorReporterService } from './external-error-reporter.service';

@Injectable()
export class ExternalErrorHandler implements ErrorHandler {
  constructor(private readonly externalErrorReporterService: ExternalErrorReporterService) {}

  public handleError(error: unknown): void {
    this.externalErrorReporterService.report(error);
  }
}
