import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { AvailableExternalScanConfigSource, Board, ServiceName } from '@neuralegion/api';
import { AzureOrg, Integration, SnykOrg } from '../models';

export interface IntegrationsState extends EntityState<Integration> {
  error: string | null;
  azureOrgs: AzureOrg[] | null;
  snykOrgs: SnykOrg[] | null;
  pending: Action[];
  repositories: Map<ServiceName, Record<string, (Board | AvailableExternalScanConfigSource)[]>>;
}

export const integrationsEntityAdapter: EntityAdapter<Integration> =
  createEntityAdapter<Integration>({
    selectId: (model) => model.id
  });

export const initialIntegrationsState: IntegrationsState =
  integrationsEntityAdapter.getInitialState({
    error: null,
    pending: [],
    azureOrgs: null,
    snykOrgs: null,
    repositories: new Map()
  });
