import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, exhaustMap, filter, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AvailableExternalScanConfigSource, Board, Scope } from '@neuralegion/api';
import { selectScopePermission } from '@neuralegion/auth-api';
import { LocationStorageService } from '@neuralegion/browser-storage';
import { AzureOrg, Integration, SnykOrg } from '../models';
import { IntegrationService } from '../services';
import {
  addGitlabIntegration,
  addGitlabIntegrationFail,
  addGitlabIntegrationSuccess,
  addIntegration,
  addIntegrationFail,
  addIntegrationSuccess,
  loadAvailableExternalScanConfigSources,
  loadAvailableExternalScanConfigSourcesFail,
  loadAvailableExternalScanConfigSourcesSuccess,
  loadAzureOrgs,
  loadAzureOrgsFail,
  loadAzureOrgsSuccess,
  loadIntegrations,
  loadIntegrationsFail,
  loadIntegrationsSuccess,
  loadRepositories,
  loadRepositoriesFail,
  loadRepositoriesSuccess,
  loadSnykOrgs,
  loadSnykOrgsFail,
  loadSnykOrgsSuccess,
  removeIntegration,
  removeIntegrationFail,
  removeIntegrationSuccess,
  updateIntegration,
  updateIntegrationFail,
  updateIntegrationSuccess
} from './integrations.actions';

@Injectable()
export class IntegrationsEffects {
  public readonly loadIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadIntegrations),
      withLatestFrom(this.store.select(selectScopePermission(Scope.INTEGRATIONS_READ))),
      exhaustMap(
        ([, integrationsReadPermission]: [ReturnType<typeof loadIntegrations>, boolean]) =>
          integrationsReadPermission
            ? this.integrationService.loadIntegrations().pipe(
                map((services: Integration[]) => loadIntegrationsSuccess(services)),
                catchError((err: HttpErrorResponse) => of(loadIntegrationsFail(err.error)))
              )
            : of(loadIntegrationsFail('Forbidden'))
      )
    )
  );

  public readonly removeIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeIntegration),
      exhaustMap((action: ReturnType<typeof removeIntegration>) =>
        this.integrationService
          .removeIntegration(action.payload.service, action.payload.integrationId)
          .pipe(
            map(() =>
              removeIntegrationSuccess({
                integrationId: action.payload.integrationId
              })
            ),
            catchError((err: HttpErrorResponse) => of(removeIntegrationFail(err.error)))
          )
      )
    )
  );

  public readonly addIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIntegration),
      exhaustMap((action: ReturnType<typeof addIntegration>) =>
        this.integrationService.addIntegration(action.payload.integration).pipe(
          map((res: { backToUrl?: string }) => addIntegrationSuccess(res)),
          catchError((err: HttpErrorResponse) => of(addIntegrationFail(err.error)))
        )
      )
    )
  );

  public readonly addGitlabIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addGitlabIntegration),
      exhaustMap((action: ReturnType<typeof addGitlabIntegration>) =>
        this.integrationService.addGitlabIntegration(action.payload.integration).pipe(
          map((gitlabInstallMetadata) =>
            addGitlabIntegrationSuccess({
              integration: action.payload.integration,
              installMetadata: gitlabInstallMetadata
            })
          ),
          catchError((err: HttpErrorResponse) => of(addGitlabIntegrationFail(err.error)))
        )
      )
    )
  );

  public readonly addGitlabIntegrationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addGitlabIntegrationSuccess),
        tap((action: ReturnType<typeof addGitlabIntegrationSuccess>) => {
          const clientId = action.payload.integration.clientId;
          const params = new HttpParams({
            fromObject: {
              nonce: action.payload.installMetadata.nonce,
              ...(clientId ? { clientId } : {})
            }
          });
          this.location.store(this.router.url);
          this.document.location.href = `
      ${this.document.location.origin}/api/v1/integrations/gitlab/install?${params.toString()}`;
        })
      ),
    { dispatch: false }
  );

  public readonly updateIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateIntegration),
      exhaustMap((action: ReturnType<typeof updateIntegration>) =>
        this.integrationService.updateIntegration(action.payload.integration).pipe(
          map(() => updateIntegrationSuccess()),
          catchError((err: HttpErrorResponse) => of(updateIntegrationFail(err.error)))
        )
      )
    )
  );

  public readonly loadAzureOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAzureOrgs),
      switchMap(() =>
        this.integrationService.loadAzureOrgs().pipe(
          map((azureOrgs: AzureOrg[]) => loadAzureOrgsSuccess(azureOrgs)),
          catchError((err: HttpErrorResponse) => of(loadAzureOrgsFail(err.error)))
        )
      )
    )
  );

  public readonly loadSnykOrgs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSnykOrgs),
      switchMap(({ integrationId }) =>
        this.integrationService.loadSnykOrgs(integrationId).pipe(
          map((orgs: SnykOrg[]) => loadSnykOrgsSuccess(orgs)),
          catchError((err: HttpErrorResponse) => of(loadSnykOrgsFail(err.error)))
        )
      )
    )
  );

  public readonly loadRepositories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRepositories),
      withLatestFrom(this.store.select(selectScopePermission(Scope.INTEGRATION_REPOS_READ))),
      switchMap(
        ([
          {
            payload: { serviceName, integrationId }
          },
          integrationReposReadPermission
        ]: [ReturnType<typeof loadRepositories>, boolean]) =>
          integrationReposReadPermission
            ? this.integrationService.loadAvailableBoards(serviceName, integrationId).pipe(
                map((repositories: Board[]) =>
                  loadRepositoriesSuccess({ integrationId, serviceName, repositories })
                ),
                catchError((err: HttpErrorResponse) => of(loadRepositoriesFail(err.error)))
              )
            : of(loadRepositoriesSuccess({ integrationId, serviceName, repositories: [] }))
      )
    )
  );

  public readonly restoreRouteAfterInstallation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addIntegrationSuccess, addIntegrationFail),
        tap(() => this.location.restore({ useRootFallback: false }))
      ),
    { dispatch: false }
  );

  public readonly redirectToBackToUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addIntegrationSuccess),
        filter((action: ReturnType<typeof addIntegrationSuccess>) => !!action.payload.backToUrl),
        tap((action: ReturnType<typeof addIntegrationSuccess>) => {
          if (action.payload.backToUrl) {
            this.document.location.href = action.payload.backToUrl;
          }
        })
      ),
    { dispatch: false }
  );

  public readonly reloadIntegrations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addIntegrationSuccess, updateIntegrationSuccess),
      map(() => loadIntegrations())
    )
  );

  public readonly closeDialogWindows$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Action>(addIntegration),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  public readonly loadExternalScanConfigSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAvailableExternalScanConfigSources),
      withLatestFrom(this.store.select(selectScopePermission(Scope.INTEGRATION_REPOS_READ))),
      switchMap(
        ([
          {
            payload: { serviceName, integrationId }
          },
          integrationReposReadPermission
        ]: [ReturnType<typeof loadAvailableExternalScanConfigSources>, boolean]) =>
          integrationReposReadPermission
            ? this.integrationService.loadAvailableIssueSources(serviceName, integrationId).pipe(
                map((repositories: AvailableExternalScanConfigSource[]) =>
                  loadAvailableExternalScanConfigSourcesSuccess({
                    integrationId,
                    serviceName,
                    configSources: repositories
                  })
                ),
                catchError((err: HttpErrorResponse) =>
                  of(loadAvailableExternalScanConfigSourcesFail(err.error))
                )
              )
            : of(
                loadAvailableExternalScanConfigSourcesSuccess({
                  integrationId,
                  serviceName,
                  configSources: []
                })
              )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly location: LocationStorageService,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly integrationService: IntegrationService
  ) {}
}
