import { Action } from '@ngrx/store';

export interface FilesState {
  readonly error: string | null;
  readonly pending: Action[];
}

export const filesInitialState: FilesState = {
  error: null,
  pending: []
};
