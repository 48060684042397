import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ForgotPasswordState } from './forgot-password.state';

type State = object;

export const selectForgotPasswordState =
  createFeatureSelector<ForgotPasswordState>('forgotPassword');

export const selectForgotPasswordPending = createSelector<State, [ForgotPasswordState], boolean>(
  selectForgotPasswordState,
  (state: ForgotPasswordState) => state.pending
);
