import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import { EntityLabels } from '@neuralegion/api';
import { LabelsStore } from '@neuralegion/core';
import { CustomValidators, extractTouchedChanges } from '../../form';
import { ServerFilterableSelectComponent } from '../server-filterable-select/server-filterable-select.component';

interface LabelsSelectOptions {
  readonly compact: boolean;
  readonly maxLabelsListLength: number;
  readonly label: string;
}

@Component({
  selector: 'share-labels-select',
  templateUrl: './labels-select.component.html',
  styleUrls: ['./labels-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: LabelsSelectComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LabelsSelectComponent,
      multi: true
    }
  ]
})
export class LabelsSelectComponent
  implements ControlValueAccessor, Validator, OnInit, OnDestroy, AfterViewInit
{
  @Input()
  public options?: Partial<LabelsSelectOptions>;

  @Output()
  public readonly openedChange = new EventEmitter<boolean>();

  @ViewChild('labelsSelect')
  private readonly labelsSelectComponent: ServerFilterableSelectComponent<string>;

  private readonly valueSubject = new BehaviorSubject<string[]>([]);

  public readonly maxLabelLength = 255;

  public readonly formControl = new FormControl<string[]>(
    [],
    [CustomValidators.uniqueCaseInsensitive, this.validateEachItemMaxLength.bind(this)]
  );

  private readonly gc = new Subject<void>();
  private onChange: (value: string[]) => void;
  private onTouched: () => void;

  public labels$: Observable<EntityLabels>;
  public labelsItems$: Observable<string[]>;
  public pending$: Observable<boolean>;
  public showFooter$: Observable<boolean>;

  private readonly defaultOptions: Partial<LabelsSelectOptions> = {
    compact: false,
    maxLabelsListLength: 15
  };

  private readonly filterByQuerySubject = new Subject<string>();
  private dropdownOpened = false;
  public readonly filterFieldValidators: ValidatorFn[] = [
    Validators.maxLength(this.maxLabelLength)
  ];

  public readonly isOptionDisabled = (item: string, selectedItems: string[]): boolean => {
    if (!selectedItems || selectedItems.length < this.options.maxLabelsListLength) {
      return false;
    }
    return !selectedItems.includes(item);
  };
  public readonly itemsSortComparator = (a: string, b: string) => a.localeCompare(b);

  constructor(private readonly labelsStore: LabelsStore) {}

  public ngOnInit(): void {
    this.options = {
      ...this.defaultOptions,
      ...(this.options || {})
    };

    this.formControl.addValidators(Validators.maxLength(this.options.maxLabelsListLength));

    this.pending$ = this.labelsStore.pending$;

    this.labels$ = this.labelsStore.labels$;
    this.labelsItems$ = this.labels$.pipe(map((labels: EntityLabels) => labels?.items || []));

    this.valueSubject
      .pipe(
        filter((value) => !equal(value, this.formControl.value)),
        takeUntil(this.gc)
      )
      .subscribe((value: string[]) => {
        this.formControl.setValue(value, { emitEvent: false });
      });

    this.formControl.valueChanges
      .pipe(
        distinctUntilChanged(equal),
        filter(() => this.formControl.dirty),
        takeUntil(this.gc)
      )
      .subscribe((v) => this.onChange?.(v));

    extractTouchedChanges(this.formControl)
      .pipe(filter(Boolean), takeUntil(this.gc))
      .subscribe(() => this.onTouched?.());

    this.initFilterByQueryListener();
  }

  public ngAfterViewInit(): void {
    this.showFooter$ = combineLatest([this.labelsItems$, this.filterByQuerySubject]).pipe(
      map(([labels, query]: [string[], string]) => {
        const canAddMoreLabels =
          (this.formControl.value?.length ?? 0) < this.options.maxLabelsListLength;

        return (
          query &&
          labels &&
          canAddMoreLabels &&
          !this.labelsSelectComponent.textFilter.errors &&
          !labels.some((option) => option === query)
        );
      })
    );
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public writeValue(value: string[]): void {
    this.valueSubject.next(value);
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  public validate(_control: AbstractControl): ValidationErrors | null {
    return this.formControl.errors;
  }

  public onQueryChange(query: string): void {
    const pastedLabels = query.split(/[;,\n]/).filter(Boolean);
    const hasPastedLabels =
      pastedLabels.length > 1 || (pastedLabels.length === 1 && pastedLabels[0] !== query);

    if (hasPastedLabels) {
      this.addLabelsToFormControl(pastedLabels);
      return;
    }
    this.filterByQuerySubject.next(query);
  }

  public onDropdownToggle(opened: boolean): void {
    this.dropdownOpened = opened;

    if (opened) {
      this.labelsStore.loadLabels('');
    } else {
      this.labelsStore.clearLabels();
    }
    this.openedChange.emit(opened);
  }

  public createLabel(label: string): void {
    if (label.length) {
      this.addLabelsToFormControl([label]);
    }
  }

  public addLabelsToFormControl(labels: string[]): void {
    const moreThanMaxLabelsToAdd =
      (this.formControl.value?.length ?? 0) + labels.length > this.options.maxLabelsListLength;
    if (moreThanMaxLabelsToAdd) {
      return;
    }

    this.formControl.setValue([...new Set([...(this.formControl.value || []), ...labels])]);
    this.formControl.markAsTouched();
    this.labelsSelectComponent.textFilter.reset('');
  }

  private initFilterByQueryListener(): void {
    this.filterByQuerySubject
      .pipe(
        debounceTime(500),
        filter(() => this.dropdownOpened && !this.labelsSelectComponent.textFilter.errors),
        takeUntil(this.gc)
      )
      .subscribe((query) => this.labelsStore.loadLabels(query));
  }

  private validateEachItemMaxLength(control: AbstractControl): ValidationErrors | null {
    if (!Array.isArray(control.value)) {
      return null;
    }

    return control.value.some((label: string) => label.length > this.maxLabelLength)
      ? { eachItemMaxLength: true }
      : null;
  }
}
