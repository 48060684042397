import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  BreadcrumbPageTitleService,
  CurlService,
  DateAdapterMat,
  EmptyValuesPreprocessorService,
  FileService,
  FontProxyService,
  HttpInterceptorEmptyValuesService,
  IconsService,
  MediaQueryService,
  ShellService,
  ThemeService,
  UserDateService
} from '../services';
import { coreReducer } from '../store/core.reducer';
import { FilesEffects } from '../store/files.effect';
import { filesReducer } from '../store/files.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature('core', coreReducer),
    StoreModule.forFeature('files', filesReducer),
    EffectsModule.forFeature([FilesEffects])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorEmptyValuesService,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themeService: ThemeService) => (): void => themeService.init(),
      deps: [ThemeService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (pageTitleService: BreadcrumbPageTitleService) => (): void =>
        pageTitleService.init(),
      deps: [BreadcrumbPageTitleService],
      multi: true
    },
    CurlService,
    DateAdapterMat,
    EmptyValuesPreprocessorService,
    FileService,
    ShellService,
    UserDateService
  ]
})
export class CoreModule {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(
    _mediaQueryService: MediaQueryService,
    _iconsService: IconsService,
    _fontProxyService: FontProxyService
  ) {}
}
