import { Pipe, PipeTransform } from '@angular/core';
import { OrganizationPermission } from '@neuralegion/api';

@Pipe({
  name: 'organizationPermission',
  pure: true
})
export class OrganizationPermissionPipe implements PipeTransform {
  public transform(value: OrganizationPermission): string {
    switch (value) {
      case OrganizationPermission.DAST:
        return 'Dynamic application security testing';

      case OrganizationPermission.RBAC:
        return 'Role-based access control';

      case OrganizationPermission.SSO:
        return 'SSO';

      case OrganizationPermission.BUSINESS_LOGIC:
        return 'Business logic tests';

      case OrganizationPermission.ORG_API_KEYS:
        return 'Organization API keys';

      case OrganizationPermission.REPEATERS:
        return 'Repeaters';

      case OrganizationPermission.FUZZER:
        return 'Fuzzer vulnerabilities';

      case OrganizationPermission.REPORTS:
        return 'Export reports: PDF, JSON';

      case OrganizationPermission.ONLY_AUTHORIZED_HOSTS:
        return 'Scanning only authorized hosts';

      case OrganizationPermission.PROJECT_REPEATERS:
        return 'Project repeaters';

      case OrganizationPermission.PROJECT_API_KEYS:
        return 'Project API keys';

      case OrganizationPermission.DEV_CENTRIC:
        return 'Developer extension';

      case OrganizationPermission.SNYK_APPS:
        return 'Snyk Apps';

      case OrganizationPermission.RDP_BASED_AUTH_RECORDER:
        return 'RDP-based Auth Recorder (for browsers with no WebRTC support)';

      case OrganizationPermission.RTC_BASED_AUTH_RECORDER:
        return 'WebRTC-based Auth Recorder';

      case OrganizationPermission.STAR_INTEGRATION:
        return 'STAR integration';

      default:
        return value;
    }
  }
}
