import {
  ChangeDetectionStrategy,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  PipeTransform,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  takeUntil
} from 'rxjs';
import equal from 'fast-deep-equal/es6';
import {
  AsyncSelectFilter,
  AsyncSelectFilterSettings,
  BaseCompositeFilter,
  CompositeFilter,
  ExternalFiltrationSelectFilter,
  Filter,
  FilterConfig,
  FilterFactory,
  FilterType,
  FilterValueObject,
  NumberCompareFilter,
  NumberCompareFilterSettings,
  SelectFilter,
  SelectFilterSettings,
  SelectItem
} from '@neuralegion/api';
import { FilterSerializationService, trackByIdentity, trackByIdx } from '@neuralegion/core';
import { extractTouchedChanges } from '../../../form';
import {
  AsyncSelectFilterItemsPipe,
  FilterLabelPipe,
  FilterStatus,
  FindFilterPipe,
  UniquePipe
} from '../../../pipes';
import { SatPopoverComponent } from '../../../sat-popover';
import { AvailableItem, NumberCompareFilterService } from '../../../services';

type AnySelectFilter = SelectFilter | AsyncSelectFilter | ExternalFiltrationSelectFilter;

interface TableFilterOptions {
  readonly config: readonly FilterConfig[];
  readonly filterNamePipe: PipeTransform;
  readonly appliedFiltersLabelsPipe?: PipeTransform;
  readonly tableName: string;
}

interface FormValue {
  filter: FilterConfig;
  value: unknown;
}

enum FiltersSettingsMode {
  VIEW,
  EDIT
}

interface AvailableFilter {
  config: FilterConfig;
  status: FilterStatus;
}

@Component({
  selector: 'share-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: TableFilterComponent,
      multi: true
    },
    NumberCompareFilterService
  ]
})
export class TableFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input()
  public options: TableFilterOptions;

  @Input()
  public length: number;

  @Input()
  public pending$: Observable<boolean>;

  @ViewChild('filtersSelect')
  private readonly filtersSelect: MatSelect;

  @ViewChild(SatPopoverComponent, { static: true })
  public popover: SatPopoverComponent;

  public readonly FilterType = FilterType;
  public readonly FiltersSettingsMode = FiltersSettingsMode;
  public readonly FilterStatus = FilterStatus;

  private readonly appliedFiltersSubject = new BehaviorSubject<CompositeFilter>(
    new CompositeFilter()
  );

  public appliedFiltersLabels$: Observable<string[]>;
  public filters: CompositeFilter = new CompositeFilter();

  public filterLabelsMap: ReadonlyMap<string, string>;

  public readonly trackByIdentity = trackByIdentity;
  public readonly trackByIdx = trackByIdx;

  public readonly form = new FormGroup(
    {
      filter: new FormControl(null, [Validators.required]),
      value: new FormControl(null, [Validators.required])
    },
    { updateOn: 'blur' }
  );

  public availableFilters$: Observable<AvailableFilter[]>;
  public readonly filtersSubject = new BehaviorSubject<CompositeFilter>(null);
  public readonly filterFormModeSubject = new BehaviorSubject<FiltersSettingsMode>(
    FiltersSettingsMode.VIEW
  );

  private readonly findFilterPipe = new FindFilterPipe();
  private readonly asyncSelectFilterItemsPipe = new AsyncSelectFilterItemsPipe();
  private readonly uniquePipe = new UniquePipe<Filter>();
  private readonly filterLabelPipe = new FilterLabelPipe();

  private readonly valueSubject = new Subject<FilterValueObject<unknown>>();
  private readonly gc = new Subject<void>();

  private onChange: (value: FilterValueObject<unknown>) => void;
  private onTouched: () => void;

  constructor(
    private readonly filterSerializationService: FilterSerializationService,
    private readonly numberCompareFilterService: NumberCompareFilterService,
    private readonly zone: NgZone
  ) {}

  public ngOnInit(): void {
    this.filterLabelsMap = new Map(
      this.options.config.map((filterConfig: FilterConfig) => [
        filterConfig.name,
        this.options.filterNamePipe.transform(filterConfig.name)
      ])
    );

    this.filtersSubject.next(this.filters);

    this.initValueSubjectListener();
    this.initTouchedListener();
    this.initFormModeListener();
    this.initFilterSelectControlListener();
    this.initFormListener();
    this.initFiltersListener();
    this.initAppliedFiltersListener();
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }

  public registerOnChange(fn: (value: FilterValueObject<unknown>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  public writeValue(value: FilterValueObject<unknown>): void {
    this.valueSubject.next(value);
  }

  public updateFilterFormMode(formMode: FiltersSettingsMode): void {
    this.filterFormModeSubject.next(formMode);
  }

  public removeFilter<T extends Filter>(tableFilter: T, filterList: BaseCompositeFilter<T>): void {
    filterList.remove(tableFilter);

    if (
      [FilterType.SELECT, FilterType.ASYNC_SELECT, FilterType.EXTERNAL_FILTRATION_SELECT].includes(
        filterList.type
      ) &&
      filterList.value.length === 0
    ) {
      this.filters.remove(filterList);
    }

    this.filtersSubject.next(this.filters);

    if (!this.filters.value.length) {
      this.updateFilterFormMode(FiltersSettingsMode.EDIT);
      this.zone.runOutsideAngular(() => {
        setTimeout(() => this.filtersSelect?.focus());
      });
    }
  }

  public apply(): void {
    this.appliedFiltersSubject.next(this.filters.clone());
    this.form.reset();

    this.onChange?.(this.filterSerializationService.serializeToObject(this.filters));
    this.closePopover();
  }

  public closePopover(): void {
    this.popover.close();
  }

  public clearAll(): void {
    this.filters = new CompositeFilter();
    this.filtersSubject.next(this.filters);

    this.updateFilterFormMode(FiltersSettingsMode.EDIT);
  }

  public onPopoverOpen(): void {
    this.filters = this.appliedFiltersSubject.value.clone();
    this.filtersSubject.next(this.filters);

    this.updateFilterFormMode(
      this.filters.value.length ? FiltersSettingsMode.VIEW : FiltersSettingsMode.EDIT
    );
  }

  public onPopoverClose(): void {
    this.filters = new CompositeFilter();
    this.filtersSubject.next(this.filters);
    this.form.reset();
  }

  private initTouchedListener(): void {
    extractTouchedChanges(this.form)
      .pipe(
        filter((touched) => touched),
        takeUntil(this.gc)
      )
      .subscribe(() => {
        this.onTouched?.();
      });
  }

  private initFormModeListener(): void {
    this.filterFormModeSubject
      .pipe(debounceTime(100), takeUntil(this.gc))
      .subscribe((formMode: FiltersSettingsMode) => {
        this.form.reset();

        // Open filters dropdown only on add filter button click
        if (formMode === FiltersSettingsMode.EDIT && this.filters.value.length) {
          this.filtersSelect.focus();
          this.filtersSelect.open();
        }
      });
  }

  private initFilterSelectControlListener(): void {
    this.form
      .get('filter')
      .valueChanges.pipe(takeUntil(this.gc))
      .subscribe(() => {
        this.form.get('value').reset();
      });
  }

  private initFormListener(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(equal),
        filter(() => this.form.valid),
        takeUntil(this.gc)
      )
      .subscribe((filterFormValue: FormValue) => {
        let tableFilter: Filter;

        switch (filterFormValue.filter.type) {
          case FilterType.SELECT:
          case FilterType.ASYNC_SELECT:
          case FilterType.EXTERNAL_FILTRATION_SELECT: {
            const selectTableFilter: AnySelectFilter =
              this.findFilterPipe.transform<AnySelectFilter>(
                filterFormValue.filter.name,
                this.filters?.value
              );

            tableFilter = selectTableFilter
              ? selectTableFilter.clone()
              : FilterFactory.createFilter(filterFormValue.filter);

            // As select filter contains its available items, remove it
            this.filters.remove(selectTableFilter);

            (tableFilter as AnySelectFilter).addValue(filterFormValue.value as string);
            break;
          }
          default:
            tableFilter = FilterFactory.createFilter(filterFormValue.filter);
            tableFilter.value = filterFormValue.value;
            break;
        }

        this.filters.add(tableFilter);

        this.filtersSubject.next(this.filters);

        this.updateFilterFormMode(FiltersSettingsMode.VIEW);
      });
  }

  private initFiltersListener(): void {
    this.availableFilters$ = this.filtersSubject.asObservable().pipe(
      switchMap((filters: CompositeFilter) =>
        combineLatest(
          this.options.config.map<Observable<AvailableFilter>>((filterConfig: FilterConfig) => {
            const tableFilter = this.findFilterPipe.transform(filterConfig.name, filters?.value);

            return (
              tableFilter
                ? this.getAvailableStatusByFilter(tableFilter)
                : this.getAvailableStatusByConfig(filterConfig)
            ).pipe(map((status: FilterStatus) => ({ status, config: filterConfig })));
          })
        )
      ),
      map((availableFilters: AvailableFilter[]) =>
        availableFilters
          .filter(
            (availableFilter: AvailableFilter) =>
              availableFilter.status !== FilterStatus.UNAVAILABLE
          )
          .sort((_a: AvailableFilter, b: AvailableFilter) =>
            b.status === FilterStatus.AVAILABLE ? 1 : -1
          )
      )
    );
  }

  private initValueSubjectListener(): void {
    this.valueSubject
      .pipe(takeUntil(this.gc))
      .subscribe((valueFilter: FilterValueObject<unknown>) => {
        const filters: CompositeFilter = this.filterSerializationService.deserializeFromObject(
          valueFilter,
          this.options.config
        ) as CompositeFilter;

        this.appliedFiltersSubject.next(filters ?? new CompositeFilter());
      });
  }

  private getAvailableStatusByFilter(tableFilter: Filter): Observable<FilterStatus> {
    switch (tableFilter.type) {
      case FilterType.TEXT:
      case FilterType.DATE_RANGE:
        return of(
          tableFilter.settings.multiple ? FilterStatus.AVAILABLE : FilterStatus.ALREADY_SELECTED
        );
      case FilterType.NUMBER_COMPARE:
        return of(
          this.numberCompareFilterService
            .getAvailableItems(
              this.filters.value.filter(
                (f: Filter) => f.name === tableFilter.name
              ) as NumberCompareFilter[],
              (tableFilter.settings as NumberCompareFilterSettings).items
            )
            .map((availableItem: AvailableItem) => availableItem.filterStatus)
            .some((filterStatus: FilterStatus) => filterStatus === FilterStatus.AVAILABLE)
            ? FilterStatus.AVAILABLE
            : FilterStatus.ALREADY_SELECTED
        );
      case FilterType.SELECT:
        return of(
          tableFilter.settings.multiple && (tableFilter as SelectFilter).availableItems?.length > 0
            ? FilterStatus.AVAILABLE
            : FilterStatus.ALREADY_SELECTED
        );
      case FilterType.ASYNC_SELECT:
        return this.asyncSelectFilterItemsPipe
          .transform(
            (tableFilter as AsyncSelectFilter).availableItems$,
            this.filters.value,
            (tableFilter as AsyncSelectFilter).settings.itemsFilterFn
          )
          .pipe(
            map((availableItems: SelectItem[]) =>
              tableFilter.settings.multiple && availableItems?.length > 0
                ? FilterStatus.AVAILABLE
                : FilterStatus.ALREADY_SELECTED
            )
          );
      case FilterType.EXTERNAL_FILTRATION_SELECT: {
        const externalSelectFilter = tableFilter as ExternalFiltrationSelectFilter;
        if (!externalSelectFilter.settings.multiple) {
          return of(FilterStatus.ALREADY_SELECTED);
        }

        if (!externalSelectFilter.settings.maxItemsLength) {
          return of(FilterStatus.AVAILABLE);
        }

        return externalSelectFilter.value.length < externalSelectFilter.settings.maxItemsLength
          ? of(FilterStatus.AVAILABLE)
          : of(FilterStatus.ALREADY_SELECTED);
      }
      default:
        return of(FilterStatus.UNAVAILABLE);
    }
  }

  private getAvailableStatusByConfig(filterConfig: FilterConfig): Observable<FilterStatus> {
    switch (filterConfig.type) {
      case FilterType.TEXT:
      case FilterType.DATE_RANGE:
      case FilterType.NUMBER_COMPARE:
      case FilterType.EXTERNAL_FILTRATION_SELECT:
        return of(FilterStatus.AVAILABLE);
      case FilterType.SELECT: {
        return of(
          (filterConfig.settings as SelectFilterSettings).items?.length > 0
            ? FilterStatus.AVAILABLE
            : FilterStatus.EMPTY
        );
      }
      case FilterType.ASYNC_SELECT: {
        return this.asyncSelectFilterItemsPipe
          .transform(
            (filterConfig.settings as AsyncSelectFilterSettings).items$,
            this.filters.value,
            (filterConfig.settings as AsyncSelectFilterSettings).itemsFilterFn
          )
          .pipe(
            map((availableItems: SelectItem[]) =>
              availableItems?.length > 0 ? FilterStatus.AVAILABLE : FilterStatus.EMPTY
            )
          );
      }
      default:
        return of(FilterStatus.UNAVAILABLE);
    }
  }

  private initAppliedFiltersListener(): void {
    this.appliedFiltersSubject
      .pipe(
        map((f: CompositeFilter) =>
          f.value.filter((v) => v.type === FilterType.EXTERNAL_FILTRATION_SELECT)
        ),
        filter((v) => !!v.length),
        distinctUntilChanged((a, b) => {
          const v1 = (a as ExternalFiltrationSelectFilter[]).flatMap((f) =>
            f.value.flatMap((v) => v.value)
          );
          const v2 = (b as ExternalFiltrationSelectFilter[]).flatMap((f) =>
            f.value.flatMap((v) => v.value)
          );

          return equal(v1, v2);
        }),
        takeUntil(this.gc)
      )
      .subscribe((data: Filter[]) => {
        const externalSelects = data as ExternalFiltrationSelectFilter[];

        externalSelects.forEach((externalSelect) => {
          const value: string[] = externalSelect.value.map((v) => v.value) as string[];

          externalSelect.settings.onAppliedFiltersChanged?.(value);
        });
      });

    this.appliedFiltersLabels$ = this.appliedFiltersSubject.pipe(
      map((f: CompositeFilter): string[] =>
        this.uniquePipe.transform(f.value, 'name').map((v: Filter) => v.name)
      ),
      map((filterNames: string[]): string[] =>
        filterNames.map((name: string) =>
          this.filterLabelPipe.transform(name, this.filterLabelsMap)
        )
      ),
      map(
        (labels: string[]) =>
          (this.options.appliedFiltersLabelsPipe?.transform(labels) ?? labels) as string[]
      )
    );
  }
}
