import { Pipe, PipeTransform } from '@angular/core';
import { ServiceName } from '@neuralegion/api';

@Pipe({
  name: 'serviceName',
  pure: true
})
export class ServiceNamePipe implements PipeTransform {
  public transform(value: ServiceName | ServiceName[]): string | string[] {
    return Array.isArray(value) ? value.map(this.format) : this.format(value);
  }

  private format(serviceName: ServiceName): string {
    switch (serviceName) {
      case ServiceName.AZURE:
        return 'Azure';
      case ServiceName.SLACK:
        return 'Slack';
      case ServiceName.GITHUB:
        return 'GitHub';
      case ServiceName.GITLAB:
        return 'GitLab Ticketing';
      case ServiceName.JIRA:
        return 'Jira';
      case ServiceName.TRELLO:
        return 'Trello';
      case ServiceName.MONDAY:
        return 'monday.com';
      case ServiceName.SERVICENOW:
        return 'ServiceNow';
      case ServiceName.SNYK:
        return 'Snyk';
      case ServiceName.STAR:
        return 'STAR';
      default:
        return serviceName;
    }
  }
}
