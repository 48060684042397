import {
  ComponentRef,
  Directive,
  Input,
  OnDestroy,
  Type,
  ViewContainerRef,
  reflectComponentType
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formControl][dynamicControl], [formControlName][dynamicControl]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DynamicControlDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DynamicControlDirective,
      multi: true
    }
  ]
})
export class DynamicControlDirective<
    Inputs extends Record<string, unknown>,
    Component extends Partial<Inputs> & ControlValueAccessor & Validator
  >
  implements OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  set dynamicControl(config: { controlComponent: Type<Component>; inputs: Inputs }) {
    // must be rendered in the next cycle
    // as actual onChange & onTouch CVA methods for component are not set in directive
    // by the moment of receiving Input data
    // see details: https://github.com/NeuraLegion/nexploit-consumer/pull/3456#discussion_r1015089408
    setTimeout(() => this.renderDynamicComponent(config));
  }

  private componentRef: ComponentRef<Component>;

  private onChange: (value: unknown) => void;
  private onTouched: () => void;

  constructor(private readonly viewContainerRef: ViewContainerRef) {}

  public registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.componentRef?.instance.setDisabledState(disabled);
  }

  public writeValue(value: unknown): void {
    this.componentRef?.instance.writeValue(value);
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.componentRef?.instance.validate(control) ?? null;
  }

  public ngOnDestroy(): void {
    this.destroyDynamicComponent();
  }

  private renderDynamicComponent(config: {
    controlComponent: Type<Component>;
    inputs: Inputs;
  }): void {
    this.destroyDynamicComponent();

    this.componentRef = this.viewContainerRef.createComponent(config.controlComponent);

    const componentInputNames = reflectComponentType(config.controlComponent).inputs.map(
      (input) => input.propName
    );
    this.setComponentInputs(config.inputs, componentInputNames);

    this.componentRef.instance.registerOnChange(this.onChange);
    this.componentRef.instance.registerOnTouched(this.onTouched);
  }

  private setComponentInputs(inputs: Inputs, availableInputs: string[]): void {
    Object.keys(inputs)
      .filter((key: string) => availableInputs.includes(key))
      .forEach((key: keyof Inputs) => {
        this.componentRef.setInput(key.toString(), inputs[key]);
      });
  }

  private destroyDynamicComponent(): void {
    this.componentRef?.destroy();
    this.componentRef = null;

    this.viewContainerRef.clear();
  }
}
