import { Injectable } from '@angular/core';
import { SentryErrorHandler, setUser } from '@sentry/angular';
import { LoggedInUser } from '@neuralegion/api';
import { ExternalErrorReporterService } from '../services';

@Injectable()
export class SentryErrorReporterService extends ExternalErrorReporterService {
  constructor(private readonly sentryErrorHandler: SentryErrorHandler) {
    super();
  }

  public setUser(user: LoggedInUser): void {
    setUser(user ? { email: user.email, id: user.id } : null);
  }

  public report(error: unknown): void {
    this.sentryErrorHandler.handleError(error);
  }
}
