import { createAction } from '@ngrx/store';
import { Email, ResetPasswordPayload, ResetPasswordVerifyPayload } from '@neuralegion/api';

export const forgotPassword = createAction(
  '[ForgotPassword] FORGOT_PASSWORD',
  (payload: Email) => ({ payload })
);

export const forgotPasswordSuccess = createAction(
  '[ForgotPassword] FORGOT_PASSWORD_SUCCESS',
  (payload: Email) => ({
    payload
  })
);

export const forgotPasswordFail = createAction(
  '[ForgotPassword] FORGOT_PASSWORD_FAIL',
  (payload: string) => ({
    payload
  })
);

export const forgotPasswordRedirect = createAction('[ForgotPassword] FORGOT_PASSWORD_REDIRECT');

export const resetPassword = createAction(
  '[ResetPassword] RESET_PASSWORD',
  (payload: ResetPasswordPayload) => ({
    payload
  })
);

export const resetPasswordSuccess = createAction(
  '[ResetPassword] RESET_PASSWORD_SUCCESS',
  (payload: { redirectToLogin: boolean }) => ({
    payload
  })
);

export const resetPasswordFail = createAction(
  '[ResetPassword] RESET_PASSWORD_FAIL',
  (payload: string) => ({
    payload
  })
);

export const verifyResetPassword = createAction(
  '[ResetPassword] VERIFY_RESET_PASSWORD',
  (payload: ResetPasswordVerifyPayload) => ({
    payload
  })
);

export const verifyResetPasswordSuccess = createAction(
  '[ResetPassword] VERIFY_RESET_PASSWORD_SUCCESS',
  (payload: { recoverToken: string }) => ({
    payload
  })
);

export const verifyResetPasswordFail = createAction(
  '[ResetPassword] VERIFY_RESET_PASSWORD_FAIL',
  (payload: string) => ({
    payload
  })
);

export const verifyResetPasswordRedirect = createAction(
  '[ResetPassword] VERIFY_RESET_PASSWORD_REDIRECT'
);
