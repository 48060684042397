import { ServiceName } from '@neuralegion/api';

export const integrationServices = [
  ServiceName.AZURE,
  ServiceName.GITHUB,
  ServiceName.GITLAB,
  ServiceName.SLACK,
  ServiceName.SNYK,
  ServiceName.STAR,
  ServiceName.MONDAY,
  ServiceName.JIRA,
  ServiceName.SERVICENOW
];
