import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, exhaustMap, map, of } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ENVIRONMENT_TOKEN, Environment, FileDownloadResponse } from '@neuralegion/api';
import { FileService } from '../services';
import {
  downloadFileByUrl,
  downloadFileByUrlFail,
  downloadFileByUrlSuccess
} from './files.actions';

@Injectable()
export class FilesEffects {
  public readonly downloadFileByUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadFileByUrl),
      exhaustMap(
        (action: ReturnType<typeof downloadFileByUrl>): Observable<Action> =>
          this.fileService.downloadFileByUrl(action.payload.url).pipe(
            map(({ file, filename }: FileDownloadResponse) => {
              this.fileService.saveBlob(file, filename);
              return downloadFileByUrlSuccess();
            }),
            catchError((err: HttpErrorResponse) => of(downloadFileByUrlFail(err.error)))
          )
      )
    )
  );

  constructor(
    @Inject(ENVIRONMENT_TOKEN) public readonly environment: Environment,
    private readonly actions$: Actions,
    private readonly fileService: FileService
  ) {}
}
