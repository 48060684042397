import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectForgotPasswordPending, verifyResetPassword } from '@neuralegion/auth-api';

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
  templateUrl: './verify-reset-password-page.component.html',
  styleUrls: ['./verify-reset-password-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerifyResetPasswordPageComponent implements OnInit, OnDestroy {
  public pending$: Observable<boolean>;
  public mfaToken$: Observable<string | null>;
  private readonly gc = new Subject<void>();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {}

  public ngOnInit(): void {
    this.pending$ = this.store.select(selectForgotPasswordPending);
    this.mfaToken$ = this.activatedRoute.params.pipe(
      map((params: { mfaToken?: string }) => params.mfaToken ?? null),
      takeUntil(this.gc)
    );
  }

  public onSubmit(event: { code: string }, mfaToken: string): void {
    this.store.dispatch(verifyResetPassword({ token: mfaToken, otp: event.code }));
  }

  public ngOnDestroy(): void {
    this.gc.next();
    this.gc.unsubscribe();
  }
}
