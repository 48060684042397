import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import fileSaver from 'file-saver';
import sanitize from 'sanitize-filename';
import { FileDownloadResponse, getFileNameFromResponseContentDisposition } from '@neuralegion/api';

interface SaveFileConfig {
  readonly data: string;
  readonly mimeType: string;
  readonly name: string;
}

@Injectable()
export class FileService {
  constructor(private readonly http: HttpClient) {}

  public getSafeFilename(filename: string): string {
    return sanitize(filename, { replacement: '_' });
  }

  public saveBlob(blob: Blob, filename: string): void {
    fileSaver.saveAs(blob, this.getSafeFilename(filename));
  }

  public saveFile(config: SaveFileConfig): void {
    this.saveBlob(new Blob([config.data], { type: config.mimeType }), config.name);
  }

  public getFileExtension(filename: string): string {
    return filename.startsWith('.') ? '' : filename.split('.').slice(1).pop() ?? '';
  }

  public formatAcceptString(fileExtensions: string[]): string {
    return fileExtensions.map((fileExtension: string) => `.${fileExtension}`).join(',');
  }

  public filterAccepted(fileList: File[], fileExtensions: string[]): File[] {
    const extensionsSet = new Set<string>(fileExtensions);
    return [...fileList].filter((file: File) =>
      extensionsSet.has(this.getFileExtension(file.name).toLowerCase())
    );
  }

  public downloadFileByUrl(url: string): Observable<FileDownloadResponse> {
    return this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map((res: HttpResponse<Blob>) => ({
          file: res.body,
          filename: getFileNameFromResponseContentDisposition(res)
        }))
      );
  }
}
