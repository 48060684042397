<mat-card>
  <mat-card-header>
    <mat-card-title role="heading" aria-level="1">Verify password reset</mat-card-title>
    <mat-card-subtitle>
      You have Two-Factor Authentication enabled on your account. To reset your password, please confirm this action
      using a one-time code.
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content *ngIf="{ pending: pending$ | async, mfaToken: mfaToken$ | async } as sync">
    <auth-mfa-form [pending]="sync.pending" (submitted)="onSubmit($event, sync.mfaToken)"> </auth-mfa-form>

    <auth-link-signin [message]="'Go back to'"></auth-link-signin>
  </mat-card-content>
</mat-card>
