<ng-container *ngIf="{ body: (payload$ | async)?.actual.body, bodyUrl: (payload$ | async)?.actual.bodyUrl } as sync">
  <ng-container *ngIf="sync.body || sync.bodyUrl">
    <mat-list-item class="title">
      <div class="body-header-container">
        <div role="heading" aria-level="3" matSubheader>Body</div>
        <div class="buttons">
          <ng-container *ngIf="sync.body; else bodyUrlButtons">
            <share-copy-button class="inline-copy-button" itemName="{{ payloadType }} body" [data]="sync.body">
            </share-copy-button>
            <button
              class="button-download"
              attr.aria-label="Download {{ payloadType }} body"
              mat-icon-button
              matTooltip="Download {{ payloadType }} body"
              (click)="downloadBodyAsFile(sync.body)">
              <mat-icon>file_download</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>
    </mat-list-item>

    <share-code-viewer *ngIf="sync.body && (code$ | async) !== null" [code$]="code$"></share-code-viewer>

    <ng-container *ngIf="sync.body; else bodyUrlContainer">
      <ng-container *ngIf="(bodyShownSubject | async) === false">
        <div class="show-body-container" [ngSwitch]="sync.body.length < thresholdBodyTooLarge">
          <button
            *ngSwitchCase="true"
            attr.aria-label="Show {{ payloadType }} body"
            mat-raised-button
            (click)="showBody()">
            Show {{ payloadType }} body ({{ sync.body.length | bytes }})
          </button>
          <span *ngSwitchCase="false" class="body-too-large">
            Body is too large ({{ sync.body.length | bytes }}) to display. Please
            <a [routerLink]="null" (click)="downloadBodyAsFile(sync.body)"> download it</a> to view
          </span>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #bodyUrlButtons>
    <button
      class="button-download"
      attr.aria-label="Download {{ payloadType }} body"
      mat-icon-button
      matTooltip="Download {{ payloadType }} body"
      (click)="downloadBodyFromUrl(sync.bodyUrl)">
      <mat-icon>file_download</mat-icon>
    </button>
  </ng-template>

  <ng-template #bodyUrlContainer>
    <div class="show-body-container">
      <span class="body-too-large">
        Body is too large to display. Please
        <button class="link link-external" (click)="downloadBodyFromUrl(sync.bodyUrl)">download it</button> to view
      </span>
    </div>
  </ng-template>
</ng-container>
